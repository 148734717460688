<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>隐患排查信息管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="现有隐患排查信息" icon="ios-people">
            <query-table
              :columnsFormatData="columnsFormatData"
              askData="latentDangerManage"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="新增隐患排查记录" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addItem"
                :model="newItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="newItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="排查时间：" prop="found_date">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="newItem.found_date"
                  ></DatePicker>
                </FormItem>
                <FormItem label="责任单位：" prop="res_department">
                  <Input v-model="newItem.res_department" placeholder=""/>
                </FormItem>
                <FormItem label="隐患情况：" prop="description">
                  <Input type="textarea" v-model="newItem.description" placeholder="" />
                </FormItem>
                <FormItem label="专业：" prop="major_system">
                  <Input v-model="newItem.major_system" placeholder=""/>
                </FormItem>
                <FormItem label="隐患类别：" prop="catagory">
                  <Input v-model="newItem.catagory" placeholder=""/>
                </FormItem>
                <FormItem label="隐患等级：" prop="danger_grade">
                  <Input v-model="newItem.danger_grade" placeholder=""/>
                </FormItem>
                <FormItem label="整改措施：" prop="correct_operation">
                  <Input v-model="newItem.correct_operation" placeholder=""/>
                </FormItem>
                <FormItem label="整改期限：" prop="correct_date_limit">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="newItem.correct_date_limit"
                  ></DatePicker>
                </FormItem>
                <FormItem label="治理情况：" prop="correct_condition">
                  <Select v-model="newItem.correct_condition" placeholder="">
                    <Option value="整改中">整改中</Option>
                    <Option value="已整改">已整改</Option>
                  </Select>
                </FormItem>
                <FormItem label="整改状态：" prop="correct_stat">
                  <Input v-model="newItem.correct_stat" placeholder=""/>
                </FormItem>
                <FormItem label="整改日期：" prop="correct_date">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="newItem.correct_date"
                  ></DatePicker>
                </FormItem>
                <FormItem label="监控防护措施：" prop="monitor_operation">
                  <Input v-model="newItem.monitor_operation" placeholder=""/>
                </FormItem>
                <FormItem label="责任人：" prop="res_person">
                  <Input v-model="newItem.res_person" placeholder=""/>
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input v-model="newItem.remark" placeholder=""/>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addItem')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addItem')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="批量添加" icon="md-cloud-upload">
            <div class="form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含隐患排查数据的excel文件：
                  <a href="/public/templates/LatentDangerTemplate.xlsx"
                    >(模板文件下载)</a
                  >
                </p>
              </div>
              <file-upload
                target="mulityByExcel"
                :iurl="post_url_tail"
                @handleSuccess="()=>this.update_count++"
              ></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>

<Modal
      v-model="scModal"
      width="800"
      :mask-closable="false"
      @on-cancel="modal_cancel"
    >
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>隐患排查信息修改</span>
      </p>
      <div class="sc-content-wrapper">
          <div class="form-wrapper">
              <Form
                ref="alterItem"
                :model="currentItem"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="请选择线路：" prop="line_code">
                  <Select  v-model="currentItem.line_code" style="width: 260px">
                      <Option
                      v-for="item in activeOption.lineChoices"
                      :value="item.line_code"
                      :key="item.line_code"
                      >{{ item.line_name }}</Option
                    >
                  </Select>
                </FormItem>
                <FormItem label="排查时间：" prop="found_date">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="currentItem.found_date"
                  ></DatePicker>
                </FormItem>
                <FormItem label="责任单位：" prop="res_department">
                  <Input v-model="currentItem.res_department" placeholder=""/>
                </FormItem>
                <FormItem label="隐患情况：" prop="description">
                  <Input type="textarea" v-model="currentItem.description" placeholder="" />
                </FormItem>
                <FormItem label="专业：" prop="major_system">
                  <Input v-model="currentItem.major_system" placeholder=""/>
                </FormItem>
                <FormItem label="隐患类别：" prop="catagory">
                  <Input v-model="currentItem.catagory" placeholder=""/>
                </FormItem>
                <FormItem label="隐患等级：" prop="danger_grade">
                  <Input v-model="currentItem.danger_grade" placeholder=""/>
                </FormItem>
                <FormItem label="整改措施：" prop="correct_operation">
                  <Input v-model="currentItem.correct_operation" placeholder=""/>
                </FormItem>
                <FormItem label="整改期限：" prop="correct_date_limit">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="currentItem.correct_date_limit"
                  ></DatePicker>
                </FormItem>
                <FormItem label="治理情况：" prop="correct_condition">
                  <Select v-model="currentItem.correct_condition" placeholder="">
                    <Option value="整改中">整改中</Option>
                    <Option value="已整改">已整改</Option>
                  </Select>
                </FormItem>
                <FormItem label="整改状态：" prop="correct_stat">
                  <Input v-model="currentItem.correct_stat" placeholder=""/>
                </FormItem>
                <FormItem label="整改日期：" prop="correct_date">
                  <DatePicker
                    type="date"
                    placeholder="选择日期"
                    style="width: 200px"
                    v-model="currentItem.correct_date"
                  ></DatePicker>
                </FormItem>
                <FormItem label="监控防护措施：" prop="monitor_operation">
                  <Input v-model="currentItem.monitor_operation" placeholder=""/>
                </FormItem>
                <FormItem label="责任人：" prop="res_person">
                  <Input v-model="currentItem.res_person" placeholder=""/>
                </FormItem>
                <FormItem label="备注：" prop="remark">
                  <Input v-model="currentItem.remark" placeholder=""/>
                </FormItem>
              </Form>
          </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save('alterItem')">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function itemFac() {
  return {
    id: 0,
    line_code:"",
    line_name:"",
    line_net:"",
    found_date:null,
    res_department:"",
    description:"",
    major_system:"",
    catagory:"",
    danger_grade:"",
    correct_operation:"",
    correct_date_limit:null,
    correct_time_date_content:"",
    correct_condition:"",
    correct_stat:"",
    correct_date:null,
    res_person:"",
    monitor_operation:"",
    remark:""
  };
}

export default {
  name: "latentDangerManage",
  components: {
    queryTable,
    fileUpload,
  },
  props: {},
  data() {
    return {
      title: "隐患排查信息",
      post_url_tail: "/latent_danger_alter",
      columnsFormatData: [
       {
          title: "线路",
          key: "line_name",
          width: 75,
          align: "center"
        },
        {
          title: "隶属分公司",
          key: "company",
          width: 130,
          align: "center"
        },
        {
          title: "排查时间",
          key: "found_date",
          width: 100,
          align: "center"
        },
        {
          title: "隐患情况",
          key: "description",
          tooltip: "true",
          align: "center",
          minWidth: 120
        },
        {
          title: "操作",
          align: "center",
          width: 130,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "green" },
                  on: {
                    click: () => {
                      this.itemEdit(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small",
                  },
                  style: { color: "red", marginLeft: "5px" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      ruleValidate: {
        line_code: [
          {
            required: true,
            type: "string",
            message: "请选择线路！",
            trigger: "blur",
          },
        ],
        found_date: [
          {
            required: true,
            type: "date",
            message: "请输入排查时间！",
            trigger: "blur",
          },
        ],
        res_department: [
          {
            required: true,
            type: "string",
            message: "请输入责任单位！",
            trigger: "blur",
          },
        ],
        major_system: [
          {
            required: true,
            type: "string",
            message: "请输入专业！",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            type: "string",
            message: "请输入隐患情况！",
            trigger: "blur",
          },
        ],
        danger_grade: [
          {
            required: true,
            type: "string",
            message: "请输入隐患等级！",
            trigger: "blur",
          },
        ],
        catagory: [
          {
            required: true,
            type: "string",
            message: "请输入隐患类别！",
            trigger: "blur",
          },
        ],
         correct_operation: [
          {
            required: true,
            type: "string",
            message: "请输入整改措施！",
            trigger: "blur",
          },
        ],
        correct_date_limit: [
          {
            required: true,
            type: "date",
            message: "请输入整改限期！",
            trigger: "blur",
          },
        ],
         correct_stat: [
          {
            required: true,
            type: "string",
            message: "请输入整改状态！",
            trigger: "blur",
          },
        ],
        correct_date: [
          {
            required: true,
            type: "date",
            message: "请输入整改日期！",
            trigger: "blur",
          },
        ],
        
        res_person: [
          {
            required: true,
            type: "string",
            message: "请输入责任人！",
            trigger: "blur",
          },
        ],
         monitor_operation: [
          {
            required: true,
            type: "string",
            message: "请输入监控预防措施！",
            trigger: "blur",
          },
        ],
      },
      option: {},
      activeOption: {},
      update_count: 0,
      loadingStatus: false,
      newItem: itemFac(),
      currentItem: itemFac(),
      scModal: false,
    };
  },
  watch: {},
  computed: {},
  created() {
    // this.getStaticOption();
    this.getActiveOption();
  },
  methods: {
    // getStaticOption() {
    //   this.$axios
    //     .post(this.$url + "/static_option", {
    //       userToken: userToken,
    //       askData: "resultRiskManage",
    //     })
    //     .then((response) => {
    //         this.option = response.data.res_record;
    //     });
    // },
    getActiveOption() {
      this.$axios
        .post(this.$url + "/active_option", {
          userToken: userToken,
          askData: "lineList",
        })
        .then((response) => {
          this.activeOption = { lineChoices: response.data.res_record };
        });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "addItem");
          form.append("newItem", JSON.stringify(this.newItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "添加成功！");
                this.update_count++;
                this.handleReset(name);
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_save(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", "alterItem");
          form.append("currentItem", JSON.stringify(this.currentItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" },
          };
          this.$axios
            .post(this.$url + this.post_url_tail, form, config)
            .then((response) => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success(this.title + "修改成功！");
                this.update_count++;
                this.scModal = false;
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch((error) => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    modal_cancel() {
      this.scModal = false;
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该条" + this.title + "信息：",
        content:
          "<p>" +
          "所属线路：" +
          value.line_name +
          "</p>" +
          "<p>" +
          "隐患情况：" +
          value.description +
          "</p>",
        onOk: () => {
          this.delItem(value.id);
        },
        onCancel: () => {},
      });
    },
    delItem(id) {
      this.$axios
        .post(this.$url + this.post_url_tail, {
          userToken: userToken,
          askData: "delItem",
          pid: id,
        })
        .then((response) => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch((error) => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
    itemEdit(value) {
      this.currentItem = itemFac();
      this.currentItem = JSON.parse(JSON.stringify(value));
      this.scModal = true;
    },
  },
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height: 700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height: 600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border: none;
}
</style>